import React from 'react';
import { FaCog } from 'react-icons/fa';
import ParticlesBg from 'particles-bg';

function App() {
  return (
    <div className="relative flex flex-col z-10 items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 overflow-hidden">
      <ParticlesBg type="cobweb" bg={true} className="absolute top-0 left-0 w-full h-full z-0 " />

      {/* Główna treść */}
      <div className="relative z-10 flex flex-col items-center">
        <FaCog
          className="animate-spin text-white text-6xl mb-6"
          style={{ animationDuration: '3s' }} // Dodany styl
        />
        <h1 className="text-4xl text-white mb-4">Common Factory</h1>
        <h2 className="text-2xl text-gray-400 mb-6">Strona w budowie</h2>
        <p className="text-lg text-gray-500 text-center px-4">
          Pracujemy nad czymś niesamowitym. Zapraszamy wkrótce!
        </p>
      </div>
    </div>
  );
}

export default App;